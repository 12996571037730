@font-face {
  font-family: 'Inter';
  src:
    url('./fonts/Inter_v14.woff2') format('woff2'),
    url('./fonts/Inter_v14.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    url('./fonts/Inter-Italic_v14.woff2') format('woff2'),
    url('./fonts/Inter-Italic_v14.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'P22MackinacPro';
  src:
    url('./fonts/P22MackinacPro-Medium.woff2') format('woff2'),
    url('./fonts/P22MackinacPro-Medium.otf') format('opentype');
  font-weight: 500;
}

* {
  font-synthesis: none !important;
  font-family: 'Inter', sans-serif;
}
